// // Localhost
// export const API = 'http://localhost:8000/api/';
// export const APP = 'http://localhost:3000';
// export const MOBILE_APP = 'http://localhost:3001';

//staging
// export const API = 'https://api.songbird.appscore.digital/api/';
// export const APP = 'https://songbird.appscore.digital';
// export const MOBILE_APP = 'https://mobilesongbird.appscore.digital';

//production
export const API = 'https://songbirdapi.pcyc.org.au/api/';
export const APP = 'https://songbird.pcyc.org.au';
export const MOBILE_APP = 'https://songbirdmobile.pcyc.org.au/';