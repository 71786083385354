import React from 'react';
import { connect } from "react-redux";
import { hoverEnter, hoverLeave } from '../../action/user';
import { fetchASession } from '../../action/session';
import Modal from 'react-responsive-modal';
import { timeDifference } from '../../utils/date';
import { toggleSelect } from '../../action/view';
import * as Constants from '../../Constants';

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  hoverEnter,
  hoverLeave,
  fetchASession,
  toggleSelect
};
class ManageSession extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    // Detect when scrolled to bottom.
    this.refs.scroll.addEventListener("scroll", () => {
      if (
        this.refs.scroll.scrollTop + this.refs.scroll.clientHeight >=
        this.refs.scroll.scrollHeight
      ) {
        this.props.loadMore();
      }
    });
  }

  handHoverEnter(e) {
    e.preventDefault();
    this.props.hoverEnter(e.currentTarget.getAttribute('data-id'));
  }

  handHoverLeave(e) {
    e.preventDefault();
    this.props.hoverLeave();
  }

  handDelete(e) {
    e.preventDefault();
    this.onCloseModal();
    this.props.handleDelete(e.currentTarget.getAttribute('data-id'));
  }

  handleEdit(e) {
    e.preventDefault();
    this.props.fetchASession(e.currentTarget.getAttribute('data-id'))
  }

  onOpenModal = () => { this.setState({ open: true }) };
  onCloseModal = () => { this.setState({ open: false }) };

  openTypeExport() {
    this.props.toggleSelect('export', 'deliveredActivityReport');
  }

  openTypeSessionExport() {
    this.props.toggleSelect('export', 'deliveredActivityReportAllSessions');
  }

  openTypeSummaryExport() {
    this.props.toggleSelect('export', 'deliveredActivityReportSummary')
  }

  render() {
    const user = JSON.parse(localStorage.getItem('user')).user;
    const session = this.props.sessionView.session;
    const sessionList = this.props.sessionView.sessionList;
    const sessions = sessionList.map((session, key) => {
      return (
        <a href="#1" key={session.id} data-id={session.id}
          className={this.props.sessionView.session.id && this.props.sessionView.session.id === session.id ? "active" : ""}
          onMouseEnter={this.handHoverEnter.bind(this)}
          onMouseLeave={this.handHoverLeave.bind(this)}
          onClick={this.handleEdit.bind(this)}>
          <div className="list-div">
            {session.activity_type.image_url ? <div className="image-circle inline"><img className="img" src={session.activity_type.image_url} alt={session.activity_type.name} /></div>
              : <div className="user-logo-div inline">
                <div className="user-logo">
                  {session.activity_type.abbreviation}
                </div>
              </div>}
            <div className="name inline">
              {session.branch.name + " | " + session.activity_type.name}
              <br />
              <span>{timeDifference(session.delivery_date) + " . " + session.user.first_name + " " + session.user.last_name}</span>
            </div>
            <div className={this.props.userView.hover_id === session.id ? 'button-div inline float-right' : 'button-div inline float-right display-none'}>
              {user.role_id < Constants.GUEST ?
                <button onClick={this.onOpenModal.bind(this)} data-id={session.id}>Delete</button>
                : ""}
              {user.role_id < Constants.GUEST ?
                <button href="#1">Edit</button>
                : ""}
            </div>
            <div className={this.props.userView.hover_id === session.id ? 'arrow-div inline float-right display-none' : 'arrow-div inline float-right'}>
              <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
            </div>
          </div>
        </a>
      );
    });
    return (
      <div className="manage-div normal-box normal-box-margin-1 float-left" ref="scroll">
        <div className="manage-list" >
          <div className="flex">
            <h1>Manage Sessions</h1>
            {user.role_id <= Constants.GUEST ?
              <div className="export-link flex export-container ml-auto mr-6">
                <div className='clickable ml-2 export-link' onClick={this.openTypeExport.bind(this)}>Export Total</div>
                {/* <div className='clickable ml-2 export-link' onClick={this.openTypeSummaryExport.bind(this)}>Export Details</div> */}
                <div className='clickable ml-2 export-link' onClick={this.openTypeSessionExport.bind(this)}>Export All</div>
              </div>
              : ""}
          </div>

          {sessions}
        </div>
        <Modal open={this.state.open} onClose={this.onCloseModal.bind(this)} center>
          <div className="userModal">
            <p>Are you sure you want to delete this session</p>
            <div className="center">
              <button className="yesButton" data-id={session.id} onClick={this.handDelete.bind(this)}>Yes</button>
              <button className="cancelButton" onClick={this.onCloseModal.bind(this)}>Cancel</button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageSession);
