import React from 'react';
import { connect } from "react-redux";
import { toggleSelect } from '../action/view';
import * as Constants from '../Constants';
import DatePicker from "react-datepicker";
import { getDateInAuFormat, getDateInUsFormat } from '../utils/date';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    toggleSelect
};

class ExportPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customDate: 0,
            period: "2",
            minKpi: "0",
            branch: "0",
            startDate: '',
            endDate: '',
            startShow: 1,
            url: Constants.API + 'report/' + this.props.root.id + '?token=' + JSON.parse(localStorage.getItem('user')).access_token
        }
    }

    close() {
        this.props.toggleSelect();
    }

    export() {
        let url = this.state.url + '&id=' + this.state.period + '&require_kpi=' + this.state.minKpi + '&branch_id=' + this.state.branch
        if (this.state.customDate) {
            if (this.state.startDate === '' || this.state.endDate === '') {
                alert('Please select both Date From and Date To')
            } else {
                window.open(
                    url + '&startDate=' + getDateInUsFormat(this.state.startDate) + '&endDate=' + getDateInUsFormat(this.state.endDate),
                    '_blank' // <- This is what makes it open in a new window.
                );
                this.props.toggleSelect();
            }
        } else {
            window.open(
                url,
                '_blank' // <- This is what makes it open in a new window.
            );
            this.props.toggleSelect();
        }
    }

    period(e) {
        let value = e.currentTarget.value
        if (value === '6') {
            this.setState({
                ...this.state,
                customDate: 1,
                period: e.currentTarget.value
            })
        } else {
            this.setState({
                ...this.state,
                customDate: 0,
                period: e.currentTarget.value
            })
        }

    }

    branch(e) {
        this.setState({
            ...this.state,
            branch: e.currentTarget.value
        })
    }

    yesno(e) {
        this.setState({
            ...this.state,
            minKpi: e.currentTarget.getAttribute('data-value')
        })
    }

    startInputClick(e) {
        this.setState({
            ...this.state,
            startShow: 1
        });
    }

    onStartDateChange(date) {
        if (this.state.endDate !== '' && date > this.state.endDate) {
            this.setState({
                ...this.state,
                startDate: date,
                endDate: '',
                startShow: 0
            });
        } else {
            this.setState({
                ...this.state,
                startDate: date,
                startShow: 0
            });
        }
    }

    endInputClick(e) {
        this.setState({
            ...this.state,
            startShow: 0
        });
    }

    onEndDateChange(date) {
        if (date < this.state.startDate) {
            this.setState({
                ...this.state,
                startDate: date,
                endDate: '',
            });
        } else {
            this.setState({
                ...this.state,
                endDate: date
            });
        }
    }

    render() {
        let branchList = this.props.branchView.branchList.map((branch, key) => {
            if (this.props.root.id === "workplanReport") {
                return (branch.workplan_count ? <option value={branch.id} key={key}>{branch.name}</option> : "")
            } else {
                return (branch.activity_session_count ? <option value={branch.id} key={key}>{branch.name}</option> : "")
            }
        });
        branchList = branchList.filter(function checkAdult(branch) {
            return branch !== "";
        });

        const startDate = this.state.startDate === '' ? 'DD-MM-YYYY' : getDateInAuFormat(this.state.startDate)
        const endDate = this.state.endDate === '' ? 'DD-MM-YYYY' : getDateInAuFormat(this.state.endDate)
        return (
            <div className={"type-export type-popup" + (this.props.root.id === "workplanReport" ? " active" : "") + (this.state.customDate ? " custom-date" : "") + (branchList.length > 1 ? " branch-select" : "")} >
                <div className="field-group">
                    <div className="field">
                        <label className="inline">Select Period</label>
                        <select className="inline" onChange={this.period.bind(this)}>
                            <option value="2">This Half Year</option>
                            <option value="3">Last Half Year</option>
                            <option value="4">This Year</option>
                            <option value="5">Last Year</option>
                            <option value="6">Custom Date Range</option>
                        </select>
                    </div>
                    {this.props.root.id === "workplanReport" ?
                        <div className="field">
                            <label className="inline">Include Mini KPI</label>
                            <div className="yesno inline">
                                <div className={"inline clickable" + (this.state.minKpi === "1" ? " active" : "")} data-value="1" onClick={this.yesno.bind(this)}>Yes</div>
                                <div className={"inline clickable" + (this.state.minKpi === "0" ? " active" : "")} data-value="0" onClick={this.yesno.bind(this)}>No</div>
                            </div>
                        </div>
                        : ""}
                    {branchList.length > 1 ?
                        <div className="field">
                            <label className="inline">Club</label>
                            <select className="inline" onChange={this.branch.bind(this)}>
                                <option value="0">All</option>
                                {branchList}
                            </select>
                        </div>
                        : ""}

                    {this.state.customDate ?
                        <div className="datepicker-div">
                            <div className="input-div">
                                <div className="inline reltv">
                                    <label>From Date:</label>
                                    <br />
                                    <input type="text" readOnly className={"date-picker" + (this.state.startShow ? ' active' : '')}
                                        value={startDate}
                                        onClick={this.startInputClick.bind(this)}></input>
                                    <img src='/img/calendar.svg' alt="calendar" className="calendar-svg" />
                                </div>
                                <div className="inline reltv">
                                    <label>To Date:</label>
                                    <br />
                                    <input type="text" readOnly className={"date-picker" + (!this.state.startShow ? ' active' : '')}
                                        value={endDate}
                                        onClick={this.endInputClick.bind(this)}></input>
                                    <img src='/img/calendar.svg' alt="calendar" className="calendar-svg" />
                                </div>
                            </div>

                            <div className={"start-datepicker custom-datepicker" + (this.state.startShow ? ' active' : '')}>
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={this.onStartDateChange.bind(this)}
                                    selectsStart
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    inline
                                />
                            </div>
                            <div className={"end-datepicker custom-datepicker" + (!this.state.startShow ? ' active' : '')}>
                                <DatePicker
                                    selected={this.state.endDate}
                                    onChange={this.onEndDateChange.bind(this)}
                                    selectsEnd
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    inline
                                />
                            </div>
                        </div>
                        : ""}
                    <button className="white-red-button inline" onClick={this.export.bind(this)}>Export</button>
                    <button className="grey-white-button inline" onClick={this.close.bind(this)}>Close</button>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExportPopup);
