import React from 'react';
import { connect } from "react-redux";
import { fetchBranchList, branchNameChange, upsertBranch } from '../../action/branch';
import { hoverEnter, hoverLeave, fetchUserList } from '../../action/user';
import { toggleSelect } from '../../action/view';

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  fetchBranchList,
  branchNameChange,
  upsertBranch,
  hoverEnter,
  hoverLeave,
  toggleSelect,
  fetchUserList
};
class EditBranchForm extends React.Component {

  constructor(props) {
    super(props);
    if (this.props.userView.userList.length === 0)
      this.props.fetchUserList();
  }

  handHoverEnter(e) {
    e.preventDefault();
    this.props.hoverEnter(e.currentTarget.getAttribute('data-id'));
  }

  handHoverLeave(e) {
    e.preventDefault();
    this.props.hoverLeave();
  }

  handleBranchNameChange(e) {
    e.preventDefault();
    this.props.branchNameChange(e.currentTarget.value);
  }

  toggleSelect(e) {
    e.preventDefault();
    this.props.toggleSelect();
  }

  upsertBranch(e) {
    e.preventDefault();
    this.props.upsertBranch(this.props.branchView.branch, 'create');
  }

  render() {
    const userList = this.props.branchView.branch.users;
    const userCount = userList ? userList.length : 0;
    const branchStr = userList ? userList.map((user, key) => {
      return (
        (key < 5) ? <div className="inline user-logo" key={key}>{user.first_name.charAt(0) + user.last_name.charAt(0)}</div> : ""
      )
    }
    ) : "";

    const errors = this.props.branchView.errors;
    const server_errors = this.props.branchView.server_errors;
    const server_error_str = server_errors ? server_errors.map((error, key) =>
      <div className="error-img-div" key={key}>
        <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{error}
      </div>
    ) : "";
    return (
      <div className="upsert-one-element-div normal-box float-left">
        <div className="upsert-one-element" >
          {server_errors ?
            <div className="error-div">
              {server_error_str}
            </div> : ""}
          <h1>Add Club</h1>
          <div className="field-group">
            <div className="field">
              <label className="inline">Name of Club</label>
              <input className="inline" placeholder="Club Name" type="text"
                value={this.props.branchView.branch.name}
                onChange={this.handleBranchNameChange.bind(this)}></input>
            </div>
            {errors && errors.name ?
              <div className="error-img-div">
                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.name}
              </div> : ""}
          </div>
          <div className="field-group">
            <div className="field with-arrow" onClick={this.toggleSelect.bind(this)}>
              <div className="inline">
                <label className="inline">Assigned Users</label>
                {userCount > 0 ? branchStr : "No assigned users"}
                <div className="more inline">{userCount > 5 ? " +" + (userCount - 5) + " More" : ""}</div>

              </div>
              <div className="inline arrow-div">
                <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
              </div>
            </div>
          </div>
          <button className={server_errors || (errors && Object.keys(errors).length > 2) ? "red-white-button not-ab" : "red-white-button"} onClick={this.upsertBranch.bind(this)}>Save</button>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBranchForm);
