import * as Constants from '../Constants';

const branchView = (state = Constants.branchViewInitialState, action) => {
    let branch = state.branch;
    switch (action.type) {
        case Constants.RECEIVE_BRANCH_LIST:
            return {
                branchList: action.branchList,
                branch: state.branch
            }
        case Constants.FETCH_A_BRANCH:
            let returnBranch = {};
            for (var i = 0; i < state.branchList.length; i++) {
                let branch = state.branchList[i];
                if (branch.id === parseInt(action.id)) {
                    returnBranch = branch;
                    break;
                }
            }
            return {
                branchList: state.branchList,
                branch: {
                    id: returnBranch.id,
                    name: returnBranch.name,
                    users: returnBranch.users
                }
            }
        case Constants.BRANCH_NAME_CHANGE:
            return {
                branchList: state.branchList,
                branch: {
                    id: state.branch.id ? state.branch.id : 0,
                    name: action.name,
                    users: state.branch.users ? state.branch.users : []
                }
            }
        case Constants.ASSIGN_BRANCH_USERS:
            branch.users = action.users;
            return {
                branchList: state.branchList,
                branch: branch
            }

        case Constants.RECEIVE_UPDATE_BRANCH:
            for (var i2 = 0; i2 < state.branchList.length; i2++) {
                let branch = state.branchList[i2];
                if (branch.id === parseInt(action.branch.id)) {
                    state.branchList[i2].name = action.branch.name;
                    state.branchList[i2].users_count = action.branch.users.length;
                    state.branchList[i2].users = action.branch.users;
                    break;
                }
            }
            return {
                branchList: state.branchList,
                branch: action.branch
            }
        case Constants.PAGE_CHANGE:
            return {
                branchList: state.branchList,
                branch: {
                    name: '',
                    users: []
                }
            }
        case Constants.SHOW_INLINE_ERROR_MSG:
            return {
                branchList: state.branchList,
                branch: state.branch,
                errors: action.errors
            }

        case Constants.SHOW_SERVER_ERROR_MSG:
            return {
                branchList: state.branchList,
                branch: state.branch,
                server_errors: action.errors,
            }
        case Constants.RECEIVE_CREATE_BRANCH:
            return {
                add_success: true,
                message: action.message,
                branchList: state.branchList,
                branch: action.branch
            }
        case Constants.RECEIVE_DELETE_A_BRANCH:
            return {
                branchList: state.branchList.filter(function(branch) {
                    return branch.id !== parseInt(action.id);
                }),
                branch: {}
            }
        default:
            return state;
    }
}

export default branchView;