import React from 'react';
import { connect } from "react-redux";
import { hoverEnter, hoverLeave } from '../../action/user';
import { toggleSelect, closeImage, previewFile, updateField } from '../../action/view';
import { upsertActivity } from '../../action/activity';
import * as Constants from '../../Constants';



function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  hoverEnter,
  hoverLeave,
  toggleSelect,
  closeImage,
  previewFile,
  updateField,
  upsertActivity
};
class EditActivityForm extends React.Component {

  upsertActivity(e) {
    e.preventDefault();
    this.props.upsertActivity(this.props.activityView.activity, 'create');
  }

  handleImageClose(e) {
    e.preventDefault();
    this.props.closeImage(Constants.ACTIVITY_VIEW);
  }

  handleImageUpload(e) {
    e.preventDefault();
    document.getElementById("activity-image-file").click();
  }

  previewFile(e) {
    e.preventDefault();
    this.props.previewFile('activity-image-file',Constants.ACTIVITY_VIEW);
  }

  updateTextField(e) {
    e.preventDefault();
    this.props.updateField(e.currentTarget.getAttribute('data-field'), Constants.ACTIVITY_VIEW, e.currentTarget.value);
  }

  updateSelectDiv(e) {
    e.preventDefault();
    let field = e.currentTarget.getAttribute('data-field');
    let view = Constants.ACTIVITY_VIEW;
    let type = e.currentTarget.getAttribute('data-type');
    let data = e.currentTarget.getAttribute('data-id');
    if (type === Constants.DATA_TYPE_INT){
      data = parseInt(data);
    }
    this.props.updateField(field, view, data);
  }

  render() {
    const activity = this.props.activityView.activity;
    const url = activity.image_base_64 ? activity.image_base_64 : (activity.image_url ? activity.image_url : null) 
    const errors = this.props.activityView.errors;
    const server_errors = this.props.activityView.server_errors;
    const server_error_str = server_errors ? server_errors.map((error, key) =>
      <div className="error-img-div" key={key}>
        <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{error}
      </div>
    ) : "";
    return (
      <div className="activity upsert-one-element-div normal-box float-left">
        <div className="upsert-one-element" >
          {server_errors ?
            <div className="error-div">
              {server_error_str}
            </div> : ""}
          <h1>Add Activity</h1>
          {url ?
            <div className="image-circle reltv center big">
              <img className="img big" src={url} alt={activity.name} id="activity-image"/>
              <div className="image-overlay ab round"></div>
              <img src='/img/image-close.svg' alt="Close" className="image-close clickable" onClick={this.handleImageClose.bind(this)}/>
            </div>
            :
            <div>
            <div className="upload-div center clickable" onClick={this.handleImageUpload.bind(this)}>
              <img className="img" src='/img/upload.svg' alt="Upload" />
            </div>
            <label>Note: Please only upload image in the format of png, jpg and jpeg</label>
            <input type="file" id='activity-image-file' onChange={this.previewFile.bind(this)} hidden></input>
            </div>
          }
           {errors && errors.image ?
              <div className="error-img-div">
                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.image}
              </div> : ""}
          <div className="field-group">
            <div className="field">
              <label className="inline">Name of Activity</label>
              <input className="inline" placeholder="Activity Name" type="text"
                value={this.props.activityView.activity.name}
                onChange={this.updateTextField.bind(this)}
                data-field="name"></input>
            </div>
            {errors && errors.name ?
              <div className="error-img-div">
                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.name}
              </div> : ""}
          </div>

          <div className="field-group">
            <div className="field">
              <label className="inline">Character's</label>
              <input className="inline" placeholder="Abbreviation" type="text"
                value={this.props.activityView.activity.abbreviation}
                onChange={this.updateTextField.bind(this)}
                data-field="abbreviation"></input>
            </div>
            {errors && errors.abbreviation ?
              <div className="error-img-div">
                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.abbreviation}
              </div> : ""}
          </div>

          <div className="field-group">
            <div className="field">
              <label className="inline">Type</label>
              <div className="role inline activity">
                <div className={activity.type && activity.type === Constants.TYPE_DIRECT ? 'role-div inline active' : 'role-div inline'} 
                data-id={Constants.TYPE_DIRECT}
                data-field="type"
                data-type={Constants.DATA_TYPE_STR}
                onClick={this.updateSelectDiv.bind(this)}>Direct</div>
                <div className={activity.type && activity.type === Constants.TYPE_INDIRECT ? 'role-div inline active' : 'role-div inline'} 
                data-id={Constants.TYPE_INDIRECT}
                data-field="type"
                onClick={this.updateSelectDiv.bind(this)}>Indirect</div>
              </div>
            </div>
            {errors && errors.type ?
                <div className="error-img-div">
                  <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.type}
                </div> : ""}
          </div>
          <div className="field-group">
            <div className="field">
              <label className="inline">Instructor Needed?</label>
              <div className="role inline activity">
                <div className={activity.require_instructor ? 'role-div inline active' : 'role-div inline'} 
                data-id='1'
                data-type={Constants.DATA_TYPE_INT}
                data-field="require_instructor"
                onClick={this.updateSelectDiv.bind(this)}>Yes</div>
                <div className={activity.require_instructor ? 'role-div inline' : 'role-div inline active'} 
                data-id='0'
                data-field="require_instructor"
                data-type={Constants.DATA_TYPE_INT}
                onClick={this.updateSelectDiv.bind(this)}>No</div>
              </div>
            </div>
          </div>

          <div className="field-group">
            <div className="field">
              <label className="inline">Indemnification?</label>
              <div className="role inline activity">
                <div className={activity.require_indemnification ? 'role-div inline active' : 'role-div inline'} 
                data-id='1'
                data-field="require_indemnification"
                data-type={Constants.DATA_TYPE_INT}
                onClick={this.updateSelectDiv.bind(this)}>Yes</div>
                <div className={activity.require_indemnification ? 'role-div inline' : 'role-div inline active'} 
                data-id='0'
                data-field="require_indemnification"
                data-type={Constants.DATA_TYPE_INT}
                onClick={this.updateSelectDiv.bind(this)}>No</div>
              </div>
            </div>
          </div>

          <button className="red-white-button" onClick={this.upsertActivity.bind(this)}>Add</button>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditActivityForm);
