import axios from 'axios';
import * as Env from '../env';
import * as Constants from '../Constants';
// import { fetchingStart } from './view';
import { showInlineErrorMsg, showServerErrorMsg } from './common';

export const requestBranchList = () => ({ type: Constants.REQUEST_BRANCH_LIST });
export const receiveBranchList = (data) => ({
    type: Constants.RECEIVE_BRANCH_LIST,
    branchList: data.data
});

function fetchBranchListAPI() {

    return dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        // dispatch(fetchingStart())
        return axios.get(Env.API + 'branch/list', axiosConfig)
            .then(response => response.data)
            .then(data => dispatch(receiveBranchList(data)))
            .catch(function(error) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            })
    }
}

export function fetchBranchList() {
    return (dispatch) => {
        return dispatch(fetchBranchListAPI())
    }
}


export const fetchABranch = (id) => ({
    type: Constants.FETCH_A_BRANCH,
    id: id
});

export const branchNameChange = (name) => ({
    type: Constants.BRANCH_NAME_CHANGE,
    name: name
});

export const assignBranchUsers = (users) => ({
    type: Constants.ASSIGN_BRANCH_USERS,
    users: users
});


function upsertBranchAPI(branch, action = 'edit') {
    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        // dispatch(fetchingStart())
        try {
            const response = await axios.post(Env.API + 'branch/' + action, branch, axiosConfig)
            const data = response.data;
            dispatch(action === 'edit' ? receiveUpdateBranch(data) : receiveCreateBranch(data));
            if (action === 'edit') 
                alert('Successfully Updated')
        } catch (error) {
            if (error.response.data.error.code === 1) {
                localStorage.removeItem('user');
                window.location.reload();
            }
            dispatch(showServerErrorMsg(error.response.data))
            
        }
    }
}

export const receiveUpdateBranch = (data) => ({
    type: Constants.RECEIVE_UPDATE_BRANCH,
    branch: data.data.branch,
    message: data.data.message
});

export const receiveCreateBranch = (data) => ({
    type: Constants.RECEIVE_CREATE_BRANCH,
    branch: data.data.branch,
    message: data.data.message
});

export function upsertBranch(branch, action = 'edit') {
    let errors = {};
    if (!branch.name) {
        errors.name = 'Club Name Is Required';
    }
    if (Object.keys(errors).length > 0) {
        return (dispatch) => {
            return dispatch(showInlineErrorMsg(errors))
        }
    } else {
        return (dispatch) => {
            return dispatch(upsertBranchAPI(branch, action))
        }
    }
}


function deleteABranchAPI(branch_id) {

    return dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        // dispatch(fetchingStart())
        return axios.get(Env.API + 'branch/delete/' + branch_id, axiosConfig)
            .then(response => response.data)
            .then(data => dispatch(receiveDeleteABranch(data)))
            .catch(function(error) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            })
    }
}

export const receiveDeleteABranch = (data) => ({
    type: Constants.RECEIVE_DELETE_A_BRANCH,
    id: data.data.id
});


export function deleteABranch(branch_id = 0) {
    return (dispatch) => {
        return dispatch(deleteABranchAPI(branch_id))
    }
}